import getters from './getters'
import actions from './actions'
import mutations from './mutations'
/*
 * isPlan 是否為定時貼文
 */
const state = {
  user: '',
  token: '',
  isPlan: false,
  planDate: '',
  dialogPayVisible: false,
  dialogPayTitle: '',
  dialogTipPayVisible: false,
  dialogItem: {},
  dialogPostItem: {},
  chatList: [],
  groupChatList:[],
  currentChat: '',
  dialogEditPostVisible: false,
  dialogEditPostId: '',
  dialogUnlockVisible: false,
  getUnreadNum: 0
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
