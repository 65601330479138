<template>
  <div v-if="isPlan" class="d-flex justify-content-between rounded bg-soft-gray mb-2">
    <div class="d-flex p-1 mt-1 text-primary">
      <i class="las la-calendar fa-2x me-1 "></i>
      <span>計畫為</span>
    </div>
    <div class="p-1 text-primary">
      <span>{{planDate}}</span>
      <button @click="clearPlan" type="button" class="btn btn-circle-sm">
        <i class="far ri-close-circle-line text-primary fa-2x"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IsPlan',
  props: ['isPlan', 'planDate'],
  methods: {
    clearPlan() {
      this.$store.dispatch('User/isPlanAction', false)
    }
  }
}
</script>
