// import { useRouter } from 'vue-router'

export default function () {
  // const router = useRouter()

  function leftMenuClose() {
    console.log('leftMenuClose')
    document.body.classList.remove('sidebar-main')
    console.log(this.$route)
  }
  // 決定是不是要顯示head
  function headShow() {
    // if (router.currentRoute.value.path === '/recommend') return
    // else return true
    return true
  }

  return {
    leftMenuClose,
    headShow
  }
}
