import {
  hasLocalStorageClientKeyToString,
  removeLocalStorageClient
} from '../utils/assist'
import axios from 'axios'

console.log(process.env)
/* create an axios instance */
const service = axios.create({
  /* url = base url + request url */
  baseURL: process.env.VUE_APP_AXIOS_BASEURL,
  /* send cookies when cross-domain requests , 跨域開啟cookie = true */
  withCredentials: false,
  /* request timeout */
  timeout: 6000000
})

/* request interceptor */
service.interceptors.request.use(
  (config) => {
    config.headers.post['Content-Type'] = 'application/json;charset=utf-8'
    let token, locale
    if (hasLocalStorageClientKeyToString('token')) {
      token = hasLocalStorageClientKeyToString('token')
      config.headers['Authorization'] = token
    }

    if (hasLocalStorageClientKeyToString('locale')) {
      locale = hasLocalStorageClientKeyToString('locale')
      config.headers['locale'] = locale
    }

    return config
  },
  (error) => {
    /* do something with request error */
    console.log(error)
    return Promise.reject(error)
  }
)

/* response interceptor */
service.interceptors.response.use(
  (response) => {
    const res = response.data
    /* if the custom code is not 20000, it is judged as an error. */
    if (res.code !== 20000) {
      /* 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired; */
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        /* to re-login */
        alert(
          'You have been logged out, you can cancel to stay on this page, or log in again'
        )
        return Promise.reject(new Error(res.message || 'Error'))
      }
    }
    return res
  },
  (error) => {
    /* debugger */
    console.log(removeLocalStorageClient)
    console.log(error.response)

    // token 無效 code 100
    if (error.response.data.code === 100) {
      removeLocalStorageClient('user')
      removeLocalStorageClient('token')
      location.href = '/auth/signin'
    }
    /* unauth */
    if (error.response.status === 401) {
      alert('401 error')
    }
    /* token已失效 */
    if (error.response.status === 500) {
      alert('500 error')
    }
    /* 錯誤在這處理 */
    return Promise.reject(error)
  }
)

export default service
