/* eslint-disable no-unused-vars */
import {
  setLocalStorageClient,
  setLocalStorageClientToString,
  removeLocalStorageClient
} from '@/utils/assist'

import { getProfile } from '@/api/user'
import { chatList } from '@/api/chat'
export default {
  addUserAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('SET_USER', payload)
      resolve()
    })
  },
  addTokenAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('SET_TOKEN', payload)
      resolve()
    })
  },
  fetchUserAction (context, payload) {
    return new Promise((resolve, reject) => {
      getProfile()
        .then(res => {
          console.log(res)
          let data = {
            user: res.user,
            token: res.token
          }
          data.user.notify_unread = res.notify_unread
          setLocalStorageClient('user', data.user)
          context.commit('SET_USER', data.user)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateChatListInObjectAction (context, payload) {
    return new Promise((resolve, reject) => {
      console.log('updateChatListInObjectAction', payload)
      context.commit('SET_CHAT_LIST', payload)
      resolve()
    })
  },

  fetcChatListAction (context, payload) {
    return new Promise((resolve, reject) => {
      const condition = {
        sort_by: 'desc',
        order_by: '',
        page_size: 10,
        page: 1
      }
      chatList(condition)
        .then(res => {
          context.commit('SET_CHAT_LIST', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setChatListAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('SET_CHAT_LIST', payload)
      resolve()
    })
  },
  appendChatListAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('APPEND_CHAT_LIST', payload)
      resolve()
    })
  },
  fetcGroupChatListAction (context, payload) {
    return new Promise((resolve, reject) => {
      const condition = {
        sort_by: 'desc',
        order_by: '',
        page_size: 99,
        page: 1
      }
      chatList(condition)
        .then(res => {
          context.commit('SET_GROUP_CHAT_LIST', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fetcChatUnReadNumtAction (context, payload) {
    return new Promise((resolve, reject) => {
      const condition = {
        sort_by: 'desc',
        order_by: '',
        page_size: 50,
        page: 1
      }
      chatList(condition)
        .then(res => {
          context.commit('SET_CHAT_UNREAD_NUM', res.total_unread)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  isPlanAction (context, payload) {
    return new Promise((resolve, reject) => {
      setLocalStorageClient('isPlan', payload)
      if (!payload) {
        removeLocalStorageClient('planDate')
      }
      context.commit('SET_IS_PLAN', payload)
      resolve()
    })
  },
  addPlanDateAction (context, payload) {
    return new Promise((resolve, reject) => {
      setLocalStorageClientToString('planDate', payload)
      context.commit('SET_PLAN_DATE', payload)
      resolve()
    })
  },
  setChatObjectAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('SET_CHAT_OBJECT', payload)
      resolve()
    })
  },
  dialogPayAction (context, payload) {
    context.commit('SET_DIALOG_PAY', payload)
  },
  dialogPayUnlockAction (context, payload) {
    context.commit('SET_DIALOG_UNLOCK_PAY', payload)
  },
  dialogPayTitleAction (context, payload) {
    context.commit('SET_DIALOG_TITLE', payload)
  },
  dialogTipPayAction (context, payload) {
    context.commit('SET_DIALOG_TIP_PAY', payload)
  },
  dialogPostItemAction (context, payload) {
    context.commit('SET_DIALOG_POST_ITEM', payload)
  },
  dialogCardConfirmAction (context, payload) {
    context.commit('SET_DIALOG_CARD_CONFIRM', payload)
  },
  dialogItemAction (context, payload) {
    context.commit('SET_DIALOG_ITEM', payload)
  },
  dialogEditPostAction (context, payload) {
    context.commit('SET_EDIT_POST', payload)
  },
  dialogEditPostIdAction (context, payload) {
    context.commit('SET_EDIT_POST_ID', payload)
  }
}
