export default {
  userState: state => state.user,
  tokenState: state => state.token,
  isPlanState: state => state.isPlan,
  chatListState: state => state.chatList,
  currentChatState: state => state.currentChat,
  planDateState: state => state.planDate,
  dialogPayVisibleState: state => state.dialogPayVisible,
  dialogPayTitleState: state => state.dialogPayTitle,
  dialogTipPayVisibleState: state => state.dialogTipPayVisible,
  dialogItemState: state => state.dialogItem,
  dialogPostItemState: state => state.dialogPostItem,
  dialogEditPostVisibleState: state => state.dialogEditPostVisible,
  dialogEditPostIdState: state => state.dialogEditPostId,
  dialogUnlockVisibleState: state => state.dialogUnlockVisible,
  unReadNumState: state => state.getUnreadNum
}
