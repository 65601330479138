export function formatDate(timestamp, short = false, type = '') {
  let new_date = new Date(timestamp)
  let dd = ''
  const prefix = '   '
  let month =
    new_date.getMonth() + 1 < 10
      ? '0' + (new_date.getMonth() + 1)
      : new_date.getMonth() + 1
  let date =
    new_date.getDate() < 10 ? '0' + new_date.getDate() : new_date.getDate()
  switch (type) {
    case 'usa':
      dd =
        month +
        '-' +
        date +
        '-' +
        new_date.getFullYear() +
        getTime(new_date, prefix)
      break
    case 'uk':
      dd =
        date +
        '-' +
        month +
        '-' +
        new_date.getFullYear() +
        getTime(new_date, prefix)
      break
    default:
      dd =
        new_date.getFullYear() +
        '-' +
        month +
        '-' +
        date +
        getTime(new_date, prefix)
      break
  }

  if (short) dd = dd.split(prefix)[0]
  return dd
}

function getTime(time, prefix = '') {
  return (
    prefix + time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds()
  )
}

export function getYesterday() {
  let dd = new Date()
  dd.setDate(dd.getDate() - 1)
  return dd
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function getCookiesInServer(req) {
  let Cookies = {}
  req &&
    req.headers.cookie &&
    req.headers.cookie.split(';').forEach(function (Cookie) {
      let parts = Cookie.split('=')
      Cookies[parts[0].trim()] = (parts[1] || '').trim()
    })
  return Cookies
}

export function encode(str) {
  return window.btoa(encodeURIComponent(str))
}

/* base64 encoded ascii to ucs-2 string */
export function decode(str) {
  return decodeURIComponent(window.atob(str))
}

export function setCookieInClient(name, value, days) {
  let exp = new Date()
  exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie =
    name + '=' + encodeURIComponent(value) + ';expires=' + exp.toGMTString()
}

export function getCookieInClient(name) {
  let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  let arr = document.cookie.match(reg)
  if (arr) {
    return decodeURIComponent(arr[2])
  } else {
    return null
  }
}

export function deleteCookie(name) {
  setCookieInClient(name, '', -1)
}

export function isLogin() {
  if (getCookieInClient('token')) {
    return true
  }
  return false
}

export function setToken(tokenValue) {
  setCookieInClient('token', tokenValue, 60 * 24 * 7)
}

export function setLocalStorageClient(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function setLocalStorageClientToString(key, data) {
  localStorage.setItem(key, data)
}

export function getLocalStorageClient(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function getLocalStorageClientToString(key) {
  return localStorage.getItem(key)
}
export function removeLocalStorageClient(key) {
  return localStorage.removeItem(key)
}

export function hasLocalStorageClientKey(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function hasLocalStorageClientKeyToString(key) {
  return localStorage.getItem(key)
}
