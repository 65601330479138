import request from '../utils/request'

// API-F30 - [SettingsController] settings/list 帳號設置列表
export function getSettings(params) {
  return request({
    url: `/settings/list`,
    method: 'get',
    params
  })
}

// API-F87 - [TagController] tag/list 取得喜愛分類
export function getTagList(params) {
  return request({
    url: `/tag/list`,
    method: 'get',
    params
  })
}
