export default ({
  getLocalStorageClient,
  getLocalStorageClientToString,
  next
}) => {
  const _user = getLocalStorageClient('user')
  const _token = getLocalStorageClientToString('token')
  if (_user && _token) {
    next('/')
    return false
  }
  next()
}
