import request from '../utils/request'

export function editProfile (data) {
  return request({
    url: `/user/editProfile`,
    method: 'put',
    data
  })
}

export function editPassword (data) {
  return request({
    url: `/user/editPassword`,
    method: 'put',
    data
  })
}

export function getProfile () {
  return request({
    url: `/user/getProfile`,
    method: 'get'
  })
}

// API-F43 - [UserController] user/referrals 推薦列表
export function getRferrals (params) {
  return request({
    url: `/user/referrals`,
    method: 'get',
    params
  })
}

// API-F57 - [UserController] user/{user_account} 其他個人資料詳細
export function getOtherProfile (params) {
  if (params.type) {
    return request({
      url: `/user/${params.user_account}?type=list`,
      method: 'get',
      params
    })
  } else {
    return request({
      url: `/user/${params.user_account}`,
      method: 'get'
    })
  }
}

// 推薦創作者 
export function getRecommendCreator (params) {
  return request({
    url: `/user/${params.user_account}`,
    method: 'get',
    params
  })
}

// API-F82
export function getCreator (params) {
  return request({
    url: `/user/publicProfile`,
    method: 'get',
    params
  })
}

export function upload (data) {
  return request({
    url: `/upload`,
    method: 'post',
    data
  })
}
