<template>
  <div class="col-lg-3 col-md-3">
    <div class="">

      <iq-card class="recommend">
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('推薦創作者') }}</h4>
        </template>
        <template v-slot:body>
          <template v-if="users.length > 0">
            <div class="block" v-for="uArr in users" :key="uArr">
              <el-carousel :interval="10000" height="350px" indicator-position="outside">
                <el-carousel-item v-for="u in uArr" :key="u" v-loading="onLoading">
                  <div class="card mb-0">
                    <div class="top-bg-image">
                      <!-- <img src="/img/profile-bg3.0fc8c31f.jpg" class="img-fluid w-100" alt="group-bg"> -->
                      <img :src="u.profile_banner" class="img-fluid w-100" alt="group-bg">
                    </div>
                    <div class="card-body text-center">
                      <div class="group-icon">
                        <img :src="u.avatar" alt="profile-img" class="rounded-circle img-fluid avatar-120">
                      </div>
                      <div class="group-info pt-3 pb-3">
                        <h4>
                          <router-link :to="`/${u.account}`">{{  u.user_name}}</router-link>
                        </h4>
                        <p @click="toLink(u.account)">{{ u.account}}</p>
                      </div>
                      <button @click="toLink(u.account)" type="submit" class="btn btn-primary d-block w-100">{{ $t('前往') }}</button>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </template>
          <div v-else class="block">暫無推薦的創作者</div>
        </template>
      </iq-card>
    </div>
  </div>
</template>
<script>
import { commomData } from '@/utils/commonVmodel'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { getRecommendCreator } from '@/api/user'
import { useRouter } from 'vue-router'

export default {
  name: 'SocialRecommendedCreators',
  setup() {
    const { proxy } = getCurrentInstance()
    const onLoading = ref(true)
    const users = ref([])
    const router = useRouter()

    const toLink = val => {
      router.push({ path: val })
    }

    const getRows = () => {
      proxy.commomData.condition.page = 1
      onLoading.value = true
      proxy.commomData.noResult = false
      const query = {
        user_account: '@',
        type: 'suggestion',
        page_size: 100
      }

      getRecommendCreator(query)
        .then(res => {
          onLoading.value = false
          for (let i = 0; i < res.data.length; i += 6) {
            users.value.push(res.data.slice(i, i + 6))
          }
        })
        .catch(err => {
          onLoading.value = false
          console.log(err)
        })
    }

    onMounted(() => {
      getRows()
    })
    return {
      toLink,
      users,
      onLoading
    }
  },

  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.recommend_wrap {
  position: absolute;
  right: 0;
  width: 23%;
  max-height: 100%;
}
.recommend {
  position: fixed;
  overflow-y: scroll;
  max-height: 78%;
  width: 20%;
}

@media screen and (max-width: 767px) {
  .recommend_wrap {
    position: unset;
    width: 100%;
    max-height: unset;
    overflow-y: unset;
  }
  .recommend {
    position: unset;
    overflow-y: unset;
    max-height: unset;
    width: 100%;
  }
}
</style>
