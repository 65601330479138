import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  layoutMode: {
    dark: false,
    rtl: false
  },
  selectedMenu: 'index',
  isPcCommentOpen: false,
  isMobilePostOpen: false,
  isShopUrl: ''
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
