export const commomData = () => {
  return {
    condition: {
      sort_by: 'desc',
      order_by: '',
      page_size: 10,
      page: 1
    },
    btnLoader: false,
    listLoader: false,
    noResult: false,
    uploadOption: {
      url: `${process.env.VUE_APP_AXIOS_BASEURL}/upload`,
      MyPayUrl: `${process.env.VUE_APP_AXIOS_BASEURL}/myPay/upload`,
      accept:
        'image/jpg,image/jpeg,image/png,video/mp4,video/ogg,video/flv,video/wmv,video/rmvb,video/mov,video/quicktime',
      maxFileSize: 10485760
    },
    tagItems: [
      {
        value: 'SG',
        label: 'SG'
      },
      {
        value: 'coser',
        label: 'coser'
      },
      {
        value: '部落客',
        label: '部落客'
      },
      {
        value: 'AV女優',
        label: 'AV女優'
      },
      {
        value: 'model',
        label: 'model'
      },
      {
        value: 'PG',
        label: 'PG'
      },
      {
        value: 'DC',
        label: 'DC'
      },
      {
        value: 'DS',
        label: 'DS'
      },
      {
        value: '直播主',
        label: '直播主'
      },
      {
        value: 'KOL',
        label: 'KOL'
      }
    ]
  }
}
