export default ({
  getLocalStorageClient,
  getLocalStorageClientToString,
  next,
  store
}) => {
  // console.log('authentication.js', getLocalStorageClient, to, from, next, store)
  const token = store.getters['User/tokenState']
  const user = store.getters['User/userState']
  const _user = getLocalStorageClient('user')
  const _token = getLocalStorageClientToString('token')
  const _isPlan = getLocalStorageClientToString('isPlan')
  const _planDate = getLocalStorageClientToString('planDate')

  // debugger
  /* storage 有就回存 vuex , 沒有的話 vuex 預設是 false*/

  if (_planDate) {
    store.commit('User/SET_PLAN_DATE', _planDate)
  }
  if (_isPlan) {
    store.commit('User/SET_IS_PLAN', JSON.parse(_isPlan)) // "true" to boolean
  }

  /* vuex 沒有，取 storage 回存 vuex */
  if (!user && !token) {
    store.dispatch('User/addTokenAction', _token)
    store.dispatch('User/addUserAction', _user)
  }

  /* storage 沒有就登出 */
  if (!_user && !_token) {
    next('/auth/promote')
    return false
  }

  next()
}
