export default {
  layoutModeCommit(state, data) {
    state.layoutMode = data
  },
  selectedMenuCommit(state, data) {
    state.selectedMenu = data
  },
  setShopUrlCommit(state, data) {
    state.isShopUrl = data;
  },
  SET_IS_PC_COMMENT_OPEN: (state, payload) => (state.isPcCommentOpen = payload),
  SET_IS_MOBILE_POST_OPEN: (state, payload) =>
    (state.isMobilePostOpen = payload)
}
