<template>
  <router-view />
</template>
<script>
import { useI18n } from 'vue-i18n'
import User from '@/store/User/'
// import { start } from '@/utils/noDebuger'
// import { useSocket } from '@/utils/useSocket'
// import { onMounted } from 'vue'
// import { useStore } from 'vuex'
export default {
  name: 'App',
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    // const { initSocket } = useSocket()
    // const store = useStore()

    // onMounted(() => {
    //   if (store.state.User.user) {
    //     store
    //       .dispatch('User/fetcChatListAction')
    //       .then(result => {
    //         console.log(
    //           'App.Vue',
    //           'User/fetcChatListAction => initSocket',
    //           result
    //         )
    //         initSocket()
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //   }
    // })
    return {
      t,
      locale
    }
  },
  computed: {},
  mounted() {
    // console.log(this.$t('msg'))
    // console.log(this.locale)
    // 禁止非法操作，觀看原始碼
    // start()
    this.$nextTick(() => {
      setTimeout(() => {
        let lastScrollTop = 0 // 記錄上次滾動位置
        // 獲取需要隱藏/顯示的元素
        const mobileOptions = document.querySelector('.mobile-options')
        const footer = document.querySelector('footer')
        // 監聽滾動事件
        // 防抖：延遲觸發滾動事件處理
        const handleScroll = () => {
          let currentScroll =
            window.pageYOffset || document.documentElement.scrollTop
          // 如果滾動條接近底部（避免回彈過多觸發）
          const nearBottom =
            window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 10
          // 向下滾動時隱藏
          if (currentScroll > lastScrollTop && currentScroll > 5) {
            console.log('向下滾動')
            mobileOptions.classList.add('hidden')
            footer.classList.add('hidden')
          } else if (currentScroll < lastScrollTop && !nearBottom) {
            console.log('向上滾動')
            mobileOptions.classList.remove('hidden')
            footer.classList.remove('hidden')
          }

          // 更新滾動位置
          lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
        }

        // 使用防抖或節流來優化滾動事件處理
        const debounceScroll = this.debounce(handleScroll, 50) // 使用 50 毫秒防抖

        // 監聽滾動事件
        window.addEventListener('scroll', debounceScroll)
      }, 500)
    })
  },
  beforeMount() {
    // 註冊模塊
    this.$store.registerModule('User', User)
  },
  methods: {
    debounce(func, delay) {
      let timeout
      return function() {
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(this, arguments), delay)
      }
    }
  },
  watch: {
    $route: function() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/socialv.scss';
@import url('./plugins/icons/remixicon/fonts/remixicon.css');
@import url('./plugins/icons/line-awesome/dist/line-awesome/css/line-awesome.min.css');
@import url('./plugins/icons/@fortawesome/fontawesome-free/css/all.min.css');
@import url('./plugins/Leaflet/leaflet.css');
@import url('./plugins/fullcalendar/core/main.css');
@import url('./plugins/fullcalendar/daygrid/main.css');
@import url('./plugins/fullcalendar/timegrid/main.css');
@import url('./plugins/fullcalendar/list/main.css');
</style>
