<template>
  <div class="col-lg-12 col-md-12">
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('推薦創作者') }}</h4>
      </template>
      <div class="R6-Wf">
        <div class="top-bg-image">
          <img src="/img/profile-bg3.0fc8c31f.jpg" class="img-fluid w-100" alt="group-bg">
        </div>
        <div class="_3TUA0" v-for="u in users" v-loading="onLoading" :key="u">
          <div class="x1MBX">
            <!-- <img :src="u.avatar" alt="profile-img" class="rounded-circle img-fluid avatar-30"> -->
            <router-link :to="`/${u.account}`">
              <div class="css-3i9vrz">
                <img :src="u.avatar" alt="profile-img" class="rounded-circle img-fluid avatar-30" />
              </div>
            </router-link>
            <router-link :to="`/${u.account}`">
              <div class="x2yyt">{{ u.account }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </iq-card>
  </div>
</template>
<script>
import { commomData } from '@/utils/commonVmodel'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { getRecommendCreator } from '@/api/user'
import { useRouter } from 'vue-router'

export default {
  name: 'SocialRecommendedCreatorsMobile',
  setup() {
    const { proxy } = getCurrentInstance()
    const onLoading = ref(true)
    const users = ref([])
    const router = useRouter()

    const toLink = val => {
      router.push({ path: val })
    }

    const getRows = () => {
      proxy.commomData.condition.page = 1
      onLoading.value = true
      proxy.commomData.noResult = false
      const query = {
        user_account: '@',
        type: 'suggestion',
        page_size: 100
      }

      getRecommendCreator(query)
        .then(res => {
          onLoading.value = false
          console.log(res.data)
          users.value = res.data
        })
        .catch(err => {
          onLoading.value = false
          console.log(err)
        })
    }

    onMounted(() => {
      getRows()
    })
    return {
      toLink,
      users,
      onLoading
    }
  },

  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData()
    }
  },
  methods: {}
}
</script>
