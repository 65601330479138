import { setLocalStorageClientToString } from '@/utils/assist'

export default {
  layoutModeAction(context, payload) {
    if (payload.dark) {
      document.querySelector('body').classList.add('dark')
    } else {
      document.querySelector('body').classList.remove('dark')
    }
    setLocalStorageClientToString('dark', payload.dark)
    document
      .getElementsByTagName('html')[0]
      .setAttribute('dir', payload.rtl ? 'rtl' : 'ltr')
    context.commit('layoutModeCommit', {
      dark: payload.dark,
      rtl: payload.rtl
    })
  },
  selectedMenuAction(context, payload) {
    setLocalStorageClientToString('selectedMenu', payload)
    context.commit('selectedMenuCommit', payload)
  },
  setShopUrlAction(context, payload) {
    context.commit('setShopUrlCommit', payload)
  },
  changeIsPcCommentOpen: ({ commit }, payload) =>
    commit('SET_IS_PC_COMMENT_OPEN', payload),
  changeIsMobilePostOpen: ({ commit }, payload) =>
    commit('SET_IS_MOBILE_POST_OPEN', payload)
}
