export default ({ getLocalStorageClientToString, next, store }) => {
  console.log('middleware/theme')
  const _dark = getLocalStorageClientToString('dark')
  if (_dark) {
    const d = JSON.parse(_dark)
    if (d) {
      document.querySelector('body').classList.add('dark')
    }
    store.commit('Setting/layoutModeCommit', {
      dark: d,
      rtl: false
    })
  }

  const _selectedMenu = getLocalStorageClientToString('selectedMenu')
  if (_selectedMenu) {
    const e = _selectedMenu
    store.commit('Setting/selectedMenuCommit', e)
  }
  next()
}
