import request from '../utils/request'

// API-F06 - [MediaController] media/list 媒體櫃列表
export function getMedia(params) {
  return request({
    url: `/media/list`,
    method: 'get',
    params
  })
}
