import { createRouter, createWebHistory } from 'vue-router'
import authentication from '../middleware/authentication'
import isLogin from '@/middleware/isLogin'
import isPhone from '@/middleware/isPhone'
import isLicense from '@/middleware/isLicense'
import isAgentApplication from '@/middleware/isAgentApplication'
import syncProfile from '@/middleware/syncProfile'
import theme from '@/middleware/theme'

/* eslint-disable */
const childRoutes = (prop, mode) => [
  {
    path: '/:user_account',
    name: prop + '.personaldetails',
    meta: {
      auth: true,
      name: 'Some User Personal Detail Social',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/SocailMain/Profile/CreatorProfile')
  },
  // 單一篇文章
  {
    path: 'post/:post_id',
    name: prop + '.singlepost',
    meta: {
      auth: true,
      name: 'Social App Single Post ',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/Apps/Social/Post')
  },
  {
    path: '',
    name: prop + '.list',
    meta: {
      auth: true,
      name: 'Social App',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/Apps/Social/SocialApp')
  },
  {
    path: 'search',
    name: prop + '.search',
    meta: {
      auth: true,
      name: 'Social Search',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/Apps/Social/SocialSearch')
  },
  // subscription
  {
    path: 'subscription',
    name: prop + '.subscription',
    meta: {
      auth: true,
      name: 'Social Subscription',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/Apps/Social/SocialSubscription')
  },
  // follow
  {
    path: 'follow',
    name: prop + '.follow',
    meta: {
      auth: true,
      name: 'Social Follow',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/Apps/Social/SocialFollow')
  },
  {
    path: 'recommend',
    name: prop + 'Recommend.list',
    meta: {
      auth: true,
      name: 'List Recommend',
      middleware: [authentication, syncProfile, theme]
    },

    component: () => import('../views/Apps/Social/VideoApp')
  },
  {
    path: '',
    name: prop + 'Subscription.list',
    meta: { auth: true, name: 'List Subscription' },
    component: () => import('../views/Apps/Social/SocialApp')
  },
  {
    path: '',
    name: prop + 'Follow.list',
    meta: { auth: true, name: 'List Follow' },
    component: () => import('../views/Apps/Social/SocialApp')
  },
  {
    path: 'accountsetting',
    name: prop + '.accountsetting',
    meta: { auth: true, name: 'Account Setting' },
    component: () => import('../views/User/AccountSetting')
  },
  {
    path: 'privacysetting',
    name: prop + '.privacy-setting',
    meta: { auth: true, name: 'Privacy Setting' },
    component: () => import('../views/User/PrivacySetting')
  },
  {
    path: 'privacypolicy',
    name: prop + '.privacy',
    meta: { auth: true, name: 'Privacy Policy' },
    component: () => import('../views/Pages/PrivacyPolicy')
  },
  {
    path: 'termofservice',
    name: prop + '.termofservice',
    meta: { auth: true, name: 'Terms Of Use' },
    component: () => import('../views/Pages/TermsOfService')
  },
  {
    path: 'profile',
    name: prop + '.profilemain',
    meta: {
      auth: true,
      name: 'Profile',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/SocailMain/Profile/Profile')
  },
  {
    path: 'profileimage',
    name: prop + '.profileimage',
    meta: {
      auth: true,
      name: 'Profile Image',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/SocailMain/Profile/ProfileImage')
  },
  {
    path: 'profilevideo',
    name: prop + '.profilevideo',
    meta: { auth: true, name: 'Profile Video' },
    component: () => import('../views/SocailMain/Profile/ProfileVideo')
  },
  {
    path: 'notification',
    name: prop + '.notification',
    meta: {
      auth: true,
      name: 'Notification',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/SocailMain/Notifications/Notification')
  },
  {
    path: 'lists',
    name: prop + 'TagList.lists',
    meta: {
      auth: true,
      name: 'lists page',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/SocailMain/Lists/List')
  },
  {
    // 添加信用卡
    path: 'add-card',
    name: prop + '.add-card',
    meta: {
      auth: true,
      name: 'AddCard',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/SocailMain/Card/AddCard')
  },
  {
    // 添加銀行
    path: 'add-bank',
    name: prop + '.add-bank',
    meta: {
      auth: true,
      name: 'AddBank',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/SocailMain/Bank/AddBank')
  },
  {
    // 媒體庫類別
    path: 'media-list',
    name: prop + '.media-list',
    meta: { auth: true, name: 'MediaList' },
    component: () => import('../views/SocailMain/Profile/MediaList')
  }
]
const blankchildRoutes = (prop, mode) => [
  {
    path: 'omnichannel',
    name: prop + '.omnichannel',
    meta: {
      auth: true,
      name: 'omniChannel',
      middleware: [theme, authentication, theme]
    },
    component: () => import('../views/AuthPages/OmniChannel')
  },
  {
    path: 'terms',
    name: prop + '.terms',
    meta: {
      auth: true,
      name: 'terms',
      middleware: [theme]
    },
    component: () => import('../views/AuthPages/terms')
  },
  {
    path: 'privacy',
    name: prop + '.privacy',
    meta: {
      auth: true,
      name: 'privacy',
      middleware: [theme]
    },
    component: () => import('../views/AuthPages/privacy')
  },
  {
    path: 'verifyphone',
    name: prop + '.verifyphone',
    meta: {
      auth: true,
      name: 'verifyPhone',
      middleware: [authentication, isPhone, theme]
    },
    component: () => import('../views/AuthPages/VerifyPhone')
  },
  {
    path: 'verifylicense',
    name: prop + '.verifylicense',
    meta: {
      auth: true,
      name: 'verifyLicense',
      middleware: [authentication, isLicense, theme]
    },
    component: () => import('../views/AuthPages/VerifyLicense')
  },
  {
    path: 'specialStore',
    name: prop + '.specialStore',
    meta: {
      auth: true,
      name: 'specialStore',
      middleware: [authentication, isAgentApplication, theme]
    },
    component: () => import('../views/AuthPages/SpecialStore')
  },
  {
    path: 'signin',
    name: prop + '.sign-in',
    meta: { auth: false, name: 'signIn', middleware: [isLogin, theme] },
    component: () => import('../views/AuthPages/Default/SignIn')
  },
  {
    path: 'promote',
    name: prop + '.promote',
    meta: { auth: false, name: 'promote', middleware: [theme] },
    component: () => import('../views/AuthPages/Default/Promote')
  },
  {
    path: 'home',
    name: prop + '.home',
    meta: { auth: false, name: 'home' },
    component: () => import('../views/AuthPages/Default/Home')
  },
  {
    path: 'signup',
    name: prop + '.sign-up',
    meta: { auth: false, name: 'signUp', middleware: [isLogin, theme] },
    component: () => import('../views/AuthPages/Default/SignUp')
  },
  {
    path: 'recoverpassword',
    name: prop + '.recoverpassword1',
    component: () => import('../views/AuthPages/Default/RecoverPassword1')
  },
  {
    path: 'vaildemail',
    name: prop + '.vaildemail',
    meta: { auth: false, name: 'vaildEmail', middleware: [isLogin, theme] },
    component: () => import('../views/AuthPages/Default/vaildEmail')
  },
  {
    path: 'lockscreen',
    name: prop + '.lockscreen1',
    component: () => import('../views/AuthPages/Default/LockScreen1')
  },
  {
    path: 'confirmmail',
    name: prop + '.confirmmail1',
    component: () => import('../views/AuthPages/Default/ConfirmMail1')
  }
]
const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    component: () => import('../views/Pages/ErrorPage')
  },
  {
    path: 'error404',
    name: prop + '.error404',
    component: () => import('../views/Pages/Error404')
  },
  {
    path: 'error500',
    name: prop + '.error500',
    component: () => import('../views/Pages/Error500')
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    component: () => import('../views/Pages/ComingSoon')
  },
  {
    path: 'test-vr',
    name: prop + '.test-vr',
    component: () => import('../views/Pages/TestVR')
  }
]

const appChildRoute = (prop, mode = false) => [
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: {
      auth: true,
      name: 'Calendar',
      img: require('../assets/images/page-img/profile-bg6.jpg'),
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/Apps/Calendar/Calendar')
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: {
      auth: true,
      name: 'Chat',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/Apps/Chat/Index')
  }
]
const defaultlayout = (prop, mode = false) => [
  {
    path: 'timelines',
    name: prop + '.timelines',
    meta: { auth: true, name: 'Timelines' },
    component: () => import('../views/Pages/TimeLines')
  },

  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { auth: true, name: 'Blank Page' },
    component: () => import('../views/Pages/BlankPage')
  },

  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true, name: 'Faq' },
    component: () => import('../views/Pages/FAQ')
  }
]

const userChildRoute = (prop, mode = false) => [
  {
    path: 'profile-edit',
    name: prop + '.profile-edit',
    meta: {
      auth: true,
      name: 'Edit Profile',
      middleware: [authentication, syncProfile, theme]
    },
    component: () => import('../views/User/ProfileEdit')
  },
  {
    path: 'accountsetting',
    name: prop + '.accountsetting',
    meta: { auth: true, name: 'Account Setting' },
    component: () => import('../views/User/AccountSetting')
  },
  {
    path: 'privacysetting',
    name: prop + '.privacy-setting',
    meta: { auth: true, name: 'Privacy Setting' },
    component: () => import('../views/User/PrivacySetting')
  },
  {
    path: 'earnings',
    name: prop + '.earnings',
    meta: {
      auth: true,
      name: 'Earnings Setting',
      middleware: [authentication, theme],
      userType: 2
    },
    component: () => import('../views/User/Earnings')
  },
  {
    path: 'goods',
    name: prop + '.goods',
    meta: {
      auth: true,
      name: 'Goods Setting',
      middleware: [authentication, theme],
      userType: 2
    },
    component: () => import('../views/User/Goods')
  },
  {
    path: 'order-buy',
    name: prop + '.order-buy',
    meta: {
      auth: true,
      name: 'Order Setting buy',
      middleware: [authentication, theme],
      userType: 2
    },
    component: () => import('../views/User/OrderBuy')
  },
  {
    path: 'order-sale',
    name: prop + '.order-sale',
    meta: {
      auth: true,
      name: 'Order Setting sale',
      middleware: [authentication, theme],
      userType: 2
    },
    component: () => import('../views/User/OrderSale')
  },
  // 其他設置
  {
    path: 'other-setting',
    name: prop + '.other-setting',
    meta: {
      auth: true,
      name: 'Other Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/OtherSeting')
  },
  // 聊天設定歡迎詞
  {
    path: 'chat-seting',
    name: prop + '.chat-seting',
    meta: {
      auth: true,
      name: 'ChatSeting Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/ChatSeting')
  },
  // QR碼
  {
    path: 'qr-seting',
    name: prop + '.qr-seting',
    meta: {
      auth: true,
      name: 'Qr Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/QrSeting')
  },

  {
    path: 'payouts',
    name: prop + '.payouts',
    meta: {
      auth: true,
      name: 'Payouts Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/Payouts')
  },
  {
    path: 'report',
    name: prop + '.report',
    meta: {
      auth: true,
      name: 'Report Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/Report')
  },
  {
    path: 'referrals',
    name: prop + '.referrals',
    meta: {
      auth: true,
      name: 'Referrals Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/Referrals')
  },
  {
    path: 'referrals-user-list',
    name: prop + '.referrals-user-list',
    meta: {
      auth: true,
      name: 'Referral user Setting',
      middleware: [authentication, syncProfile, theme],
      userType: 2
    },
    component: () => import('../views/User/ReferralsUserList')
  }
]

const routes = [
  {
    path: '/',
    name: 'social',
    component: () => import('../layouts/Default'),
    children: childRoutes('social')
  },
  {
    path: '/without-leftside',
    name: 'without-leftside',
    component: () => import('../layouts/WithoutLeftside'),
    children: childRoutes('leftside')
  },
  {
    path: '/without-rightside',
    name: 'without-rightside',
    component: () => import('../layouts/WithoutRightside'),
    children: childRoutes('rightside')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/Empty'),
    children: blankchildRoutes('auth1')
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('../layouts/Empty'),
    children: pagesChildRoutes('default')
  },

  {
    path: '/app',
    name: 'app',
    component: () => import('../layouts/Default'),
    meta: { auth: true },
    children: appChildRoute('app')
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: () => import('../layouts/Default'),
    meta: { auth: true },
    children: defaultlayout('extra-pages')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../layouts/Default'),
    meta: { auth: true },
    children: userChildRoute('user')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

export default router
