import request from '../utils/request'

// API-F47 - [PostController] post/list 貼文列表
export function getPost (params) {
  return request({
    url: `/post/list`,
    method: 'get',
    params
  })
}

// API-F50 - [PostController] post/{post_id}/like 貼文按讚
export function addPostLike (data) {
  return request({
    url: `/post/${data.post_id}/like`,
    method: 'post',
    data
  })
}

// API-F48 - [PostController] post/{post_id}/comment/create 貼文留言
export function addPostComment (data) {
  return request({
    url: `/post/${data.post_id}/comment/create`,
    method: 'post',
    data
  })
}

// API-F49 - [PostController] post/comment/{post_comment_id}/delete 刪除留言
export function delPost (data) {
  return request({
    url: `/post/comment/${data.post_comment_id}/delete`,
    method: 'delete'
  })
}

// API-F52 - [PostController] post/comment/{post_comment_id}/like 留言按讚
export function addCommentLike (data) {
  return request({
    url: `/post/comment/${data.post_comment_id}/like`,
    method: 'post',
    data
  })
}

// API-F53 - [PostController] post/{post_id}/follow 追蹤貼文
export function addFollow (data) {
  return request({
    url: `/post/${data.post_id}/follow`,
    method: 'post',
    data
  })
}

// API-F58 - [PostController] post/{post_id}/report 貼文舉發

export function addReport (data) {
  return request({
    url: `/post/${data.post_id}/report`,
    method: 'post',
    data
  })
}

// API-F36 - [PostController] post/create 發布貼文
export function addPost (data) {
  return request({
    url: `/post/create`,
    method: 'post',
    data
  })
}

// API-F75 - [PostController] post/{post_id}/detail 貼文詳細
export function getPostById (data) {
  return request({
    url: `post/${data}/detail`,
    method: 'get'
  })
}

// API-F45 - [PostController] post/{post_id}/delete 刪除貼文
export function delP (data) {
  return request({
    url: `/post/${data.post_id}/delete`,
    method: 'delete'
  })
}

// API-F46 - [PostController] post/{post_id}/edit 編輯貼文
export function updatePost (data) {
  return request({
    url: `post/${data.post_id}/edit`,
    method: 'put',
    data
  })
}

// API-F83 - [PostController] post/recommendList 推薦貼文列表
export function getRecommendList(params) {
  return request({
    url: `/post/recommendList`,
    method: 'get',
    params
  })
}