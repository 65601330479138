import request from '../utils/request'

// API-F54 - [ChatController] chat/list 聊天列表
export function chatList(params) {
  return request({
    url: `/chat/list`,
    method: 'get',
    params
  })
}

//API-F55 - [ChatController] chat/{chat_id}/detail 私訊詳細
export function chatDetail(d) {
  return request({
    url: `chat/${d.chat_id}/detail`,
    method: 'get',
    params: d
  })
}

// API-F56 - [ChatController] chat/{chat_id}/messages 私訊對話
export function sendMessage(data) {
  return request({
    url: `chat/${data.chat_id}/messages`,
    method: 'post',
    data
  })
}

// API-F68 - [ChatController] chat/group 私訊群發
export function sendChatGroup(data) {
  return request({
    url: `/chat/group`,
    method: 'post',
    data
  })
}
